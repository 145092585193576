import axios from 'axios';
import authHeader from "@/api/auth-header";
import BaseService from "@/api/base.service";

const API_URL = process.env.VUE_APP_BASE_API + 'auth/'

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'login', {
                // username: user.username,
                email: user.email,
                password: user.password
            })
            .then(async response => {
                this.setLocalStorage(response)
                await this.me().then(response => {
                    return true
                });
                return response.data;
            });
    }

    setLocalStorage(response) {
        if (response.data.access_token) {
            response.data.accessToken = response.data.access_token;
            localStorage.setItem('user', JSON.stringify(response.data));
            window.Laravel = {
                csrfToken: response.data.csrf,
                jsPermissions: response.data.permissions
            }
        }
    }

    setUserDataLocalStorage(response) {
        if (response.data.data) {
            localStorage.setItem('userdata', JSON.stringify(response.data.data));
        }
    }

    logout() {
        let temp = axios.get(API_URL + 'logout', { headers: authHeader() })
        localStorage.clear()
        return temp
    }

    register(user) {
        return axios.post(API_URL + 'signup', {
            // username: user.username,
            name: user.name,
            email: user.email,
            password: user.password,
            confirm_password: user.confirm_password
        });
    }
    clearCache() {
        let websiteId = JSON.parse(localStorage.getItem('userdata'))[0].domains[0].website_id
        return BaseService.apiGetRequest('clear-cache?website_id='+websiteId);
    }
    me(user) {
        return axios
            .get(API_URL + 'user', { headers: authHeader() })
            .then(response => {
                if (response.data) {
                    this.setUserDataLocalStorage(response)
                }
                return response.data;
            });
    }
}

export default new AuthService();
