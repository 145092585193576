<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1>
          Set Template
        </h1>
      </div>
    </el-col>
    </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!templateId"
          title="Template Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="templateId"
          title="Template Updated Successfully"
          type="success"
          effect="dark"
        />
<!--        <div class="fl-left">-->
<!--          To complete the process, please clicks Install Pages, Modules, and Settings.-->
<!--          <div class="fl-left w-100 d-flex"><el-link :href="getInstallLink('pages')" target="_blank">Install Pages</el-link></div>-->
<!--          <div class="fl-left w-100 d-flex"><el-link :href="getInstallLink('snippets')" target="_blank">Install Modules</el-link></div>-->
<!--          <div class="fl-left w-100 d-flex"><el-link :href="getInstallLink('forms')" target="_blank">Install Settings</el-link></div>-->
<!--          Once installed, visit the website at <el-link :href="'https://' + getWebsiteLink()" target="_blank">{{getWebsiteLink()}}</el-link>-->
<!--        </div>-->
      </div>
    </el-col>
  </el-row>

  <el-form
      v-if="!showAlertAdded"
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition" 
  >
    <el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6"><el-form-item
		label="Select Template"
		prop="theme_id"
		>
		<el-select v-model="data.theme_id"  placeholder="Select Template" >
            <el-option
			v-for="item in templates"
			:key="item.value"
			:value="item.value"
			:label="item.label"
			@click="getTemplate(item.value)"
			></el-option>
          </el-select>
		</el-form-item></el-col>
		<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" v-if="data.preview_img !== null">
        <div>
          <h5 class="fl-left el-form-item__label" style="margin: 0 10px 0 0;">Preview</h5>
          <img v-if="data.preview_img !== null"
               :src="data.preview_img" style="max-height: 300px">
          <el-skeleton-item v-if="data.preview_img === null" variant="image" style="height: 300px;border:solid 1px;background-color: #FAFAFA;border-color: lightgray" />
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item>

          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            Submit
          </el-button>
          <el-button  v-if="!templateId" @click="resetForm('ruleForm')">
            Reset
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>

 </el-form>
</template>
<script>

import TemplateService from "@/api/template.service";
import authHeader from '@/api/auth-header';
import UserService from "@/api/user.service";
import AuthService from "@/api/auth.service";
export default {
  data() {
      return {
        userdata: null,
        showAlertAdded: false,
        labelPosition: 'top',
        templateId: this.$route.params.templateId,   
        templates:[],		
        data: {
          theme_id: null,
          preview_img:null
        }
      };
    },
	mounted() {
    this.restoreUserData()
	this.getUserWebsite();   
	this.gettemplates();   
  }, 
    methods: {
      restoreUserData() {
        let userdata = JSON.parse(localStorage.getItem('userdata'))
        let user = JSON.parse(localStorage.getItem('user'))
        if(userdata) {
          this.user = user
          this.userdata = userdata[0]
        }
      },
      getInstallLink(linkTo) {
        return 'https://' + this.getWebsiteLink() + '/api/' + linkTo + '/install';
      },
      getWebsiteLink() {
        if(this.userdata) {
          if(this.userdata.domains[0].config_custom_url1) {
            return  this.userdata.domains[0].config_custom_url1
          }
          return this.userdata.domains[0].domain
        }
        return null
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
             return TemplateService.setTheme(this.data).then(response => {
				        this.showAlertAdded=true;
              }).then(response => {
               // this.installPages()
               // this.installSnippets()
               // this.installForms()
               AuthService.clearCache()
               return response;
              });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },  
      getTemplate(templateId=null){
        return TemplateService.getTemplate(templateId).then(response => {
			this.data.theme_id		= response.data.data[0].value;
            this.data.preview_img	= response.data.data[0].preview_img;
          });
      }, 
	 getUserWebsite() {
          return TemplateService.getUserWebsite().then(response => { 
			 this.getTemplate(response.data.data[0].theme_id);
			 //this.data.theme_id		=response.data.data[0].theme_id;
			 //this.data.preview_img	=response.data.data[0].preview_image;
          });
       },
	 gettemplates(){
		return TemplateService.getTemplatesList().then(response => {
		this.templates=response.data.data;
		});
	  },
	installPages(){
		return TemplateService.installPages().then(response => {
			// console.log(response);
		});
	  },
	installSnippets(){	
		return TemplateService.installSnippets().then(response => {
			// console.log(response);
		});
	  },
	installForms(){	
		return TemplateService.installForms().then(response => {
			// console.log(response);
		});
	  }
  }
}
</script>
<style lang="scss">
.el-checkbox{
  width: 20%;
  text-transform: capitalize;
}
.el-select-dropdown{
      text-transform: capitalize;
}
.el-select .el-input__inner{
  text-transform: capitalize;
}
</style>
<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.d-flex {
  display: flex;
}
</style>
